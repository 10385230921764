<template>
  <div>
    <div v-loading="loading" class="flex-def">
      <el-input :value="choose.id > 0 ? `#${choose.id}:${choose.title}` : ''" disabled></el-input>
      <el-button @click="search" size="mini">选择商品</el-button>
    </div>
    <el-dialog title="选择商品" append-to-body :visible.sync="dialog" width="48rem" destroy-on-close>
      <el-table :data="list">
        <el-table-column prop="id" label="编号"></el-table-column>
        <el-table-column prop="title" label="标题"></el-table-column>
        <el-table-column label="操作">
          <template #default="s">
            <el-button @click="chooseGoods(s.row)" size="mini">选中</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "y_merchant_goods_choose",
  model:{
    prop:"modelVal",
    event:"change"
  },
  props:{
    modelVal:{
      type:Number,
      default:0,
    },
    merchant_id:{
      type:Number,
      default: 0,
    }
  },
  watch:{
    modelVal(){
      if(this.modelVal > 0){
        this.one();
      }
    },
    value(){
      this.$emit("change",this.value);
    },
  },
  data(){
    return{
      value:this.modelVal,
      choose:{
        id:0,
        title:"",
      },
      list:[],
      dialog:false,
      loading:false,
    }
  },
  methods:{
    chooseGoods(item){
      this.choose = item;
      this.value = item.id;
      this.dialog = false;
    },
    one(){
      if(!this.merchant_id)return;
      this.$api.plugin.shop.goodsOne({id:this.modelVal}).then(res=>{
        this.choose = res;
      })
    },
    search(){
      if(!this.merchant_id)return this.$message.info("请先选择视频所属商户");
      this.loading = true;
      this.$api.plugin.shop.goodsSearch({
        merchant_id:this.merchant_id,
        page:-1
      }).then(res=>{
        this.dialog = true;
        this.list = res.list;
        this.loading = false;
      })
    }
  }
}
</script>

<style scoped>

</style>